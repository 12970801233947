import React, { useEffect } from 'react';
import { FieldText, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import {
  Application,
  CreateApplicationDurationDto,
} from '../../../backend/careo-api';
import { UpdatePlacementFormContainer } from './update-placement-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateVacancySchema } from '../../../utils/validators/vacancy.validator';
import { handleFormsServerErrors } from '../../../utils';

export const UpdatePlacementForm = ({
  onCancel,
  onSuccess,
  placementToUpdate,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  placementToUpdate: Application;
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setFocus,
  } = useForm<CreateApplicationDurationDto>({
    resolver: yupResolver(UpdateVacancySchema as any),
  });

  const onClickSubmit = () => {
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerUpdatePlacementDuration(placementToUpdate._id, {
        ...values,
      })
      .then(() => {
        toast.success('Placement Updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    reset(placementToUpdate);
  }, [placementToUpdate]);

  return (
    <UpdatePlacementFormContainer>
      <FieldText
        label="Candidate Name"
        required
        value={
          placementToUpdate?.candidate?.firstName +
          ' ' +
          placementToUpdate?.candidate?.lastName
        }
        disabled
      />
      <FieldText
        placeholder="Enter here ..."
        label="Start Date"
        required
        type="date"
        register={register('availableFrom')}
        error={errors.availableFrom}
        min={placementToUpdate.job.vacantFrom.split('T')[0]}
        max={placementToUpdate.job.vacantTo.split('T')[0]}
      />
      <FieldText
        placeholder="Enter here ..."
        label="End Date"
        required
        type="date"
        register={register('availableTo')}
        error={errors.availableTo}
        min={placementToUpdate.job.vacantFrom.split('T')[0]}
        max={placementToUpdate.job.vacantTo.split('T')[0]}
      />
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </UpdatePlacementFormContainer>
  );
};
