import React, { useEffect } from 'react';
import { Select, TitleCard, FieldText, Button, RadioGroup } from '../../ui';
import {
  countriesWithRegions,
  genders,
  nationalities,
  regionsWithCounties,
  titles,
  jobLevelWithGrades,
} from '../../../constants';
import { useForm } from 'react-hook-form';
import {
  Candidate,
  Payroll,
  User,
  UpdateCandidateDto,
  EUserRole,
} from '../../../backend/careo-api';
import { UpdateCandidateFormContainer } from './update-candidate-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { candidateSchema } from '../../../utils/validators/candidates.validator';
import { handleFormsServerErrors } from '../../../utils';
import { useAuth } from '../../../contexts/auth.context';
import { isCRMApp } from '../../../environment/app.type';

type UpdateCandidateFormProps = {
  usersList: User[];
  payrollsList: Payroll[];
  onCancel: () => void;
  onSuccess: () => void;
  selectedCandidate: Candidate;
};

export const UpdateCandidateForm = ({
  usersList,
  onCancel,
  onSuccess,
  payrollsList,
  selectedCandidate,
}: UpdateCandidateFormProps) => {
  const { user } = useAuth();
  const isAdmin = user?.role === EUserRole.Admin;

  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<UpdateCandidateDto>({
    resolver: yupResolver(candidateSchema as any),
  });
  const formValues = watch();

  const recruitersList = usersList.filter((el) =>
    [
      EUserRole.Admin,
      EUserRole.Recruiter,
      EUserRole.RecruitmentOfficer,
    ].includes(el.role as EUserRole),
  );

  const officersList = usersList.filter((el) =>
    [EUserRole.Admin, EUserRole.Officer, EUserRole.RecruitmentOfficer].includes(
      el.role as EUserRole,
    ),
  );

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.candidates
      .candidatesControllerUpdate(selectedCandidate._id, values)
      .then(() => {
        toast.success('Candidate updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    reset({
      ...selectedCandidate,
      recruiterId: selectedCandidate.recruiter?._id,
      officerId: selectedCandidate.officer?._id,
      payrollProviderId: selectedCandidate.payrollProvider?._id,
      birthDate: selectedCandidate.birthDate?.split?.('T')?.[0] ?? '',
    } as UpdateCandidateDto);
  }, [selectedCandidate]);

  return (
    <UpdateCandidateFormContainer>
      {isCRMApp ? (
        <Select
          disabled={!isAdmin}
          placeholder="Select recruiter"
          required
          label={'Recruiter'}
          options={recruitersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          register={register('recruiterId')}
          error={errors.recruiterId}
          control={control}
        />
      ) : (
        <Select
          disabled={!isAdmin}
          placeholder="Select Officer"
          required
          label={'Officer'}
          options={officersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          register={register('officerId')}
          error={errors.officerId}
          control={control}
        />
      )}
      <TitleCard>Personal Details</TitleCard>
      <div className="row">
        <div className="col-md-2">
          <Select
            placeholder="Title"
            label="Title"
            required
            options={titles.map((el) => ({ label: el, value: el }))}
            register={register('title')}
            error={errors.title}
            control={control}
          />
        </div>
        <div className="col-md-5">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('firstName')}
            error={errors.firstName}
          />
        </div>
        <div className="col-md-5">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('lastName')}
            error={errors.lastName}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Email"
            required
            register={register('email')}
            error={errors.email}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.phoneNumber}
            placeholder="+442071234567"
            label="Mobile Phone Number"
            required
            register={register('phoneNumber')}
            error={errors.phoneNumber}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.homePhoneNumber}
            placeholder="+442071234567"
            label="Home Phone Number"
            register={register('homePhoneNumber')}
            error={errors.homePhoneNumber}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.workPhoneNumber}
            placeholder="+442071234567"
            label="Work Phone Number"
            register={register('workPhoneNumber')}
            error={errors.workPhoneNumber}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Date of Birth"
            type="date"
            value={formValues.birthDate}
            register={register('birthDate')}
            error={errors.birthDate}
            max={new Date().toJSON().split('T')[0]}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Nationality"
            options={nationalities.map((el) => ({ label: el, value: el }))}
            register={register('nationality')}
            error={errors.nationality}
            control={control}
          ></Select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Professional Registration Number"
            register={register('professionalRegistrationNumber')}
            error={errors.professionalRegistrationNumber}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Professional Registration Authority"
            register={register('professionalRegistrationAuthority')}
            error={errors.professionalRegistrationAuthority}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Gender"
            options={genders.map((el) => ({ label: el, value: el }))}
            register={register('gender')}
            error={errors.gender}
            control={control}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="NI Number"
            register={register('niNumber')}
            error={errors.niNumber}
          />
        </div>
      </div>
      <TitleCard>Type of Employment</TitleCard>
      <RadioGroup
        register={register('currentlyEmployed')}
        name="currentlyEmployed"
        error={errors.currentlyEmployed}
        options={[
          {
            label: 'Employed',
            value: true,
          },
          {
            label: 'Not Employed',
            value: false,
          },
        ]}
      />
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Department"
            register={register('department')}
            error={errors.department}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Select destination"
            label="Job title"
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
            register={register('designation')}
            error={errors.designation}
            control={control}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Select
            placeholder="Select grade"
            label="Grade"
            options={
              jobLevelWithGrades
                .find((el) => el.level === formValues.designation)
                ?.grades?.map((el) => ({ label: el, value: el })) ?? []
            }
            register={register('grade')}
            error={errors.grade}
            control={control}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Pay Amount"
            type="number"
            min={0}
            register={register('payAmount')}
            error={errors.payAmount}
          />
        </div>
      </div>
      {/* address form */}
      <>
        <TitleCard>Address</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          label="Street Address"
          register={register('address.street')}
          error={errors?.address?.street}
        />
        <FieldText
          placeholder="Enter here ..."
          label="City"
          register={register('address.city')}
          error={errors?.address?.city}
        />
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select country"
              label="Country"
              options={countriesWithRegions.map((el) => ({
                label: el.country,
                value: el.country,
              }))}
              register={register('address.country')}
              error={errors?.address?.country}
              onChange={() => {
                setValue('address.region', '');
                setValue('address.county', '');
              }}
              control={control}
            />
          </div>
          <div className="col-md-6">
            <Select
              placeholder="Select Region"
              label="Region"
              options={
                countriesWithRegions
                  .find((el) => el.country === formValues.address?.country)
                  ?.regions?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.region')}
              error={errors?.address?.region}
              disabled={!formValues.address?.country}
              control={control}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select County"
              label="County"
              options={
                regionsWithCounties
                  .find((el) => el.region === formValues.address?.region)
                  ?.counties?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.county')}
              error={errors?.address?.county}
              disabled={!formValues.address?.region}
              control={control}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Postcode"
              register={register('address.zip')}
              error={errors?.address?.zip}
            />
          </div>
        </div>
      </>
      <TitleCard>Payroll</TitleCard>
      <RadioGroup
        name="payroll"
        options={[
          {
            label: 'Paye',
            value: 'paye',
          },
        ]}
      />
      <Select
        placeholder="Select Payroll"
        label="Payee options"
        options={payrollsList.map((el) => ({
          label: el.payrollProviderName,
          value: el._id,
        }))}
        register={register('payrollProviderId')}
        error={errors.payrollProviderId}
        control={control}
      />
      <div className="form-actions">
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </UpdateCandidateFormContainer>
  );
};
