import React from 'react';
import styled from 'styled-components';
import {
  DeleteIcon,
  FileIcon,
  InfoIcon,
  MailIcon,
  SavedIcon,
} from '../../icons';
import { BOX_TYPE } from '../../backend/careo-api';
import { EmailBodyUi } from '../../pages/emails';

export const EmailsTypeContainer = styled.div`
  height: 100%;
  border-radius: 10px;
  border: 1px solid #e9eaec;
  background: #fff;
  .item-email-type {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    cursor: pointer;
    &.active,
    &:hover {
      background: #f5f7fb;
    }
    .item-icon {
      margin-right: 10px;
      svg {
        path {
          fill: #a0aec0;
        }
      }
    }
    .item-title {
      font-size: 14px;
      flex: 1;
    }
    .item-total {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 100px;
      background: #e03137;
      color: white;
      font-size: 14px;
    }
  }
`;

type EmailsTypeListProps = {
  className?: string;
  emailType: BOX_TYPE;
  setEmailType: React.Dispatch<React.SetStateAction<BOX_TYPE>>;
  setSelectedEmail: React.Dispatch<
    React.SetStateAction<EmailBodyUi | undefined>
  >;
};

export const EmailsTypeList = ({
  className = '',
  emailType,
  setEmailType,
  setSelectedEmail,
}: EmailsTypeListProps) => {
  return (
    <EmailsTypeContainer className={className}>
      <div
        className={`item-email-type ${
          emailType === BOX_TYPE.INBOX && 'active'
        }`}
        onClick={() => {
          setEmailType(BOX_TYPE.INBOX);
          setSelectedEmail(undefined);
        }}
      >
        <div className="item-icon">
          <MailIcon />
        </div>
        <div className="item-title">Inbox</div>
        {/* <div className="item-total">8</div> */}
      </div>

      <div
        className={`item-email-type ${emailType === BOX_TYPE.SENT && 'active'}`}
        onClick={() => {
          setEmailType(BOX_TYPE.SENT);
          setSelectedEmail(undefined);
        }}
      >
        <div className="item-icon">
          <SavedIcon />
        </div>
        <div className="item-title">Sent</div>
      </div>

      <div
        className={`item-email-type ${
          emailType === BOX_TYPE.DRAFT && 'active'
        }`}
        onClick={() => {
          setEmailType(BOX_TYPE.DRAFT);
          setSelectedEmail(undefined);
        }}
      >
        <div className="item-icon">
          <FileIcon />
        </div>
        <div className="item-title">Drafts</div>
        {/* <div className="item-total">2</div> */}
      </div>

      <div
        className={`item-email-type ${emailType === BOX_TYPE.SPAM && 'active'}`}
        onClick={() => {
          setEmailType(BOX_TYPE.SPAM);
          setSelectedEmail(undefined);
        }}
      >
        {' '}
        <div className="item-icon">
          <InfoIcon />
        </div>
        <div className="item-title">Spam</div>
        {/* <div className="item-total">1</div> */}
      </div>

      <div
        className={`item-email-type ${
          emailType === BOX_TYPE.TRASH && 'active'
        }`}
        onClick={() => {
          setEmailType(BOX_TYPE.TRASH);
          setSelectedEmail(undefined);
        }}
      >
        {' '}
        <div className="item-icon">
          <DeleteIcon />
        </div>
        <div className="item-title">Trash</div>
        {/* <div className="item-total">8</div> */}
      </div>
    </EmailsTypeContainer>
  );
};
