import React, { useEffect, useState } from 'react';
import { FieldText, Button, RadioGroup } from '../../ui';
import { useForm } from 'react-hook-form';
import { AddAvailabilityDto } from '../../../backend/careo-api';
import { NewAvailabilityFormContainer } from './new-availability-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { AvailabilitySchema } from '../../../utils/validators/availability.validator';
import { yupResolver } from '@hookform/resolvers/yup';
import { availabilityTimeList } from '../../../constants';
import { handleFormsServerErrors } from '../../../utils';

type NewAvailabilityFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const NewAvailabilityForm = ({
  onCancel,
  onSuccess,
}: NewAvailabilityFormProps) => {
  const { id } = useParams();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<AddAvailabilityDto & { typeDuration: 'single' | 'multiple' }>({
    resolver: yupResolver(AvailabilitySchema as any),
  });

  const [typeDuration, setTypeDuration] = useState<'single' | 'multiple'>(
    'single',
  );

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.availability
      .availabilityControllerAddAvailability({
        ...values,
        to: typeDuration === 'single' ? undefined : values.to,
        candidateId: id!,
      })
      .then(() => {
        toast.success('Availability added successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (id) {
      setValue('candidateId', id);
      setValue('typeDuration', 'single');
    } else {
      toast.error('Please select a candidate to create availability for.');
    }
  }, [id]);

  return (
    <NewAvailabilityFormContainer>
      <RadioGroup
        name="Availability Type"
        options={[
          {
            label: 'Single Day',
            value: 'single',
          },
          {
            label: 'Multiple Day',
            value: 'multiple',
          },
        ]}
        onChange={(value) => {
          setValue('typeDuration', value as any);
          setTypeDuration(value as any);
        }}
        value={typeDuration}
        error={errors.typeDuration}
      />
      <RadioGroup
        name="Availability Time"
        options={availabilityTimeList}
        register={register('availabilityTime', { required: true })}
        error={errors.availabilityTime}
      />
      <div className="row">
        <div className={`col-md-${typeDuration === 'multiple' ? 6 : 12}`}>
          <FieldText
            placeholder="Enter here ..."
            label={typeDuration === 'multiple' ? 'From' : 'Date'}
            required
            type="date"
            register={register('from', { required: true })}
            error={errors.from}
            min={new Date().toISOString().split('T')[0]}
          />
        </div>
        {typeDuration === 'multiple' && (
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="To"
              required
              type="date"
              register={register('to', { required: true })}
              error={errors.to}
              min={new Date().toISOString().split('T')[0]}
            />
          </div>
        )}
      </div>
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewAvailabilityFormContainer>
  );
};
