import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MailIcon, SavedIcon } from '../../icons';
import { Checkbox } from '../ui';
import { EmailContent } from './emails-content.component';
import { getTimeAgo } from '../../utils';
import {
  BOX_TYPE,
  EmailAttachment,
  EmailBody,
  HostedFile,
} from '../../backend/careo-api';
import { MessageContentContainer } from '../messages/messages-content/messages-content.style';
import { EmailBodyUi, EmailsEmailUi } from '../../pages/emails';

export const EmailsTitleListContainer = styled.div`
  height: 100%;
  border-radius: 10px;
  border: 1px solid #e9eaec;
  background: #fff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .emails-list {
    flex: 1;
    .email-item {
      padding: 18px 20px;
      color: #323b49;
      font-weight: 400;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        background-color: rgba(157, 157, 157, 0.04);
      }
      .checkbox {
        display: flex;
        margin-right: 16px;
      }
      .starred {
        display: flex;
        margin-right: 10px;
        svg path {
          fill: #a0aec0;
        }
      }
      .name {
        font-size: 14px;
        min-width: 175px;
        max-width: 175px;
        margin-right: 50px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .object {
        font-size: 12px;
        flex: 1;
      }
      .time {
        font-size: 12px;
      }

      &.active {
        .name {
          font-weight: 700;
        }
        .object {
          font-weight: 700;
        }
      }
    }
  }
  .emails-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    .pagination {
      gap: 10px;
    }
    .page-item {
      &.active {
        cursor: pointer;
      }
      /* .page-link {
        background-color: #27a376;
        border-color: #27a376;
      } */
    }
  }
`;

type EmailsTitleListProps = {
  className?: string;
  dataEmails: EmailsEmailUi;
  getEmails: (pageToken?: string) => Promise<void>;
  selectedEmail?: EmailBodyUi;
  setSelectedEmail: React.Dispatch<
    React.SetStateAction<EmailBodyUi | undefined>
  >;
  sendEmail: (
    email: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => Promise<void>;
  draftEmail: (
    email: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => Promise<void>;
  deleteEmail: (id: string, isDraft?: boolean) => Promise<void>;
  updateDraftEmail: (
    id: string,
    recipient: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: EmailAttachment[],
  ) => Promise<void>;
  sendDraftEmail: (
    messageId: string,
    recipient: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: EmailAttachment[],
  ) => Promise<void>;
};

export const EmailsTitleList = ({
  className = '',
  dataEmails,
  selectedEmail,
  setSelectedEmail,
  sendEmail,
  draftEmail,
  deleteEmail,
  getEmails,
  updateDraftEmail,
  sendDraftEmail,
}: EmailsTitleListProps) => {
  const onClickEmail = (email: EmailBodyUi) => {
    setSelectedEmail(email);
  };

  if (dataEmails.isLoading && !selectedEmail) {
    return (
      <MessageContentContainer style={{ height: '100%' }}>
        <div className="no-conversation">Loading ...</div>
      </MessageContentContainer>
    );
  }

  if (selectedEmail) {
    return (
      <EmailContent
        emailBody={selectedEmail}
        sendEmail={sendEmail}
        draftEmail={draftEmail}
        deleteEmail={deleteEmail}
        setSelectedEmail={setSelectedEmail}
        sendDraftEmail={sendDraftEmail}
        updateDraftEmail={updateDraftEmail}
      />
    );
  }

  if (!dataEmails.emails.length) {
    return (
      <MessageContentContainer style={{ height: '100%' }}>
        <div className="no-conversation">No emails available</div>
      </MessageContentContainer>
    );
  }

  return (
    <EmailsTitleListContainer className={className}>
      <div className="emails-list">
        {dataEmails.emails.map((el) => (
          <div
            className={`email-item ${!el.isRead ? 'active' : ''}`}
            key={el.messageId}
            onClick={() => onClickEmail(el)}
          >
            <div className="checkbox">
              <Checkbox checked={false} onChange={() => {}} />
            </div>
            {/* <div className="starred">
          <SavedIcon />
        </div> */}
            <div className="name">{el.from}</div>
            <div className="object">{el.subject}</div>
            <div className="time">{getTimeAgo(new Date(el.date))}</div>
          </div>
        ))}
      </div>

      <div className="emails-pagination">
        <ul className="pagination">
          <li
            className={`page-item ${
              !dataEmails.previousPageToken ? 'disabled' : 'active'
            }`}
            onClick={() =>
              dataEmails.previousPageToken &&
              getEmails(dataEmails.previousPageToken)
            }
          >
            <div className="page-link" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </div>
          </li>
          <li
            className={`page-item ${
              !dataEmails.nextPageToken ? 'disabled' : 'active'
            }`}
            onClick={() =>
              dataEmails.nextPageToken && getEmails(dataEmails.nextPageToken)
            }
          >
            <div className="page-link" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </div>
          </li>
        </ul>
      </div>
    </EmailsTitleListContainer>
  );
};
