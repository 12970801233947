import React, { useEffect, useRef, useState } from 'react';
import {
  ArrowLeftUTurnIcon,
  ArrowRightUTurnIcon,
  DeleteIcon,
  DotsIcon,
  DownloadIcon,
  EditIcon,
  LeftArrowIcon,
} from '../../icons';
import { formatDateTime } from '../../utils';
import { Button, UserIcon } from '../ui';
import { BOX_TYPE, EmailAttachment, HostedFile } from '../../backend/careo-api';
import { downloadBase64File, downloadFile } from '../../utils';
import { EmailContentContainer } from './emails-content.style';
import { EmailBodyUi } from '../../pages/emails';
import EmailCreate from './email-create.component';

type EmailContentProps = {
  emailBody: EmailBodyUi;
  sendEmail: (
    email: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => Promise<void>;
  draftEmail: (
    email: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => Promise<void>;
  setSelectedEmail: React.Dispatch<
    React.SetStateAction<EmailBodyUi | undefined>
  >;
  updateDraftEmail: (
    id: string,
    recipient: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: EmailAttachment[],
  ) => Promise<void>;
  sendDraftEmail: (
    messageId: string,
    recipient: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: EmailAttachment[],
  ) => Promise<void>;
  deleteEmail: (id: string, isDraft?: boolean) => Promise<void>;
};

export const EmailContent = ({
  emailBody,
  sendEmail,
  deleteEmail,
  setSelectedEmail,
  draftEmail,
  updateDraftEmail,
  sendDraftEmail,
}: EmailContentProps) => {
  const [data, setData] = useState<EmailBodyUi>();
  const targetRef = useRef<any>(null);

  const onClickReply = () => {
    const isDraft = emailBody.labels.includes(BOX_TYPE.DRAFT);

    setData({
      from: '',
      to: [emailBody.isSent ? emailBody.to?.[0] ?? '' : emailBody.from],
      subject: `RE: ${emailBody.subject}`,
      bodyHtml: `
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div>
          <div>--------------------------------------------------</div>
          <div>Sent: ${emailBody.date}</div>
          <div>To: ${emailBody?.from}</div>
          <div>Subject: ${emailBody.subject}</div>
          <br />
          ${emailBody.bodyHtml}
        </div>
      `,
      attachments: [],
      body: '',
      date: new Date().toJSON(),
      isRead: false,
      messageId: isDraft ? emailBody.messageId : '',
      labels: [BOX_TYPE.SENT],
      isDraft,
      isSent: true,
    });
  };

  const onClickTransfer = () => {
    setData({
      from: '',
      to: [],
      subject: `TR: ${emailBody.subject}`,
      bodyHtml: `
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div>
          <div>--------------------------------------------------</div>
          <div>Sent: ${emailBody.date}</div>
          <div>To: ${emailBody?.from}</div>
          <div>Subject: ${emailBody.subject}</div>
          <br />

          ${emailBody.bodyHtml}
        </div>
      `,
      attachments: [],
      body: '',
      date: new Date().toJSON(),
      isRead: false,
      messageId: new Date().toJSON(),
      labels: [BOX_TYPE.SENT],
      isDraft: false,
      isSent: false,
    });
  };

  const onClickEdit = () => {
    setData({
      ...emailBody,
    });
  };

  const onClickDelete = () => {
    deleteEmail(emailBody.messageId).then(() => {
      setSelectedEmail(undefined);
    });
  };

  useEffect(() => {
    if (data && targetRef.current) {
      targetRef?.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [data]);

  return (
    <EmailContentContainer>
      {/* when we click on edit on draft emails we don't show the content */}
      {!(data && data.labels.includes(BOX_TYPE.DRAFT)) && (
        <div className="email-item-container">
          <div className="header-conversation">
            <div className="subject-header">
              <LeftArrowIcon onClick={() => setSelectedEmail(undefined)} />
              {emailBody.subject}
            </div>
            <div className="details-header">
              <div className="left-header">
                <div className="sender-profile-image">
                  <UserIcon
                    firstName={emailBody.isSent ? 'M' : emailBody.from}
                    lastName={emailBody.isSent ? 'E' : ''}
                    entity={emailBody.isSent ? 'user' : 'candidate'}
                  />
                </div>
                <div className="sender-info-messenger">
                  <div className="sender-name">{emailBody.from}</div>
                  <div className="receiver-name">
                    to: {emailBody.to.join(', ')}
                  </div>
                </div>
              </div>
              <div className="right-header">
                <div className="time-receiving">
                  {formatDateTime(emailBody.date)}
                </div>
                {(!emailBody.labels.includes(BOX_TYPE.DRAFT) ||
                  !emailBody.labels.includes(BOX_TYPE.TRASH)) && (
                  <>
                    <div
                      className="arrows"
                      title="reply"
                      onClick={() => onClickReply()}
                    >
                      <ArrowLeftUTurnIcon />
                    </div>
                    <div
                      className="arrows"
                      title="forward"
                      onClick={() => onClickTransfer()}
                    >
                      <ArrowRightUTurnIcon />
                    </div>{' '}
                  </>
                )}
                {!emailBody.labels.includes(BOX_TYPE.TRASH) && (
                  <div
                    className="icons"
                    title="delete"
                    onClick={() => onClickDelete()}
                  >
                    <DeleteIcon />
                  </div>
                )}
                <div className="icons">
                  <DotsIcon />
                </div>
              </div>
            </div>
          </div>

          <div className="content-conversation">
            <div className={`email-item`} key={emailBody.messageId}>
              <div className="email-attachment-file-container">
                {emailBody?.attachments?.map((file, index) => {
                  if ((file as EmailAttachment).data) {
                    return (
                      <div
                        className="attachment-file"
                        onClick={() =>
                          downloadBase64File(
                            (file as EmailAttachment).data,
                            (file as EmailAttachment).filename,
                            (file as EmailAttachment).mimeType,
                          )
                        }
                        key={index}
                      >
                        <DownloadIcon /> {(file as EmailAttachment).filename}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="attachment-file"
                        onClick={() => downloadFile(file as HostedFile)}
                        key={(file as HostedFile)._id}
                      >
                        <DownloadIcon /> {(file as HostedFile).fileName}
                      </div>
                    );
                  }
                })}
              </div>
              <div
                className="email-content"
                dangerouslySetInnerHTML={{ __html: emailBody.bodyHtml }}
              />
            </div>
            {emailBody.labels.includes(BOX_TYPE.TRASH) ? (
              <></>
            ) : emailBody.labels.includes(BOX_TYPE.DRAFT) ? (
              <div className="actions-container">
                <Button className="email-actions" onClick={() => onClickEdit()}>
                  Edit <EditIcon />
                </Button>
              </div>
            ) : (
              <div className="actions-container">
                <Button
                  className="email-actions"
                  onClick={() => onClickReply()}
                >
                  <ArrowLeftUTurnIcon /> Reply
                </Button>
                <Button
                  className="email-actions"
                  onClick={() => onClickTransfer()}
                >
                  <ArrowRightUTurnIcon /> Forward
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      {data && (
        <EmailCreate
          onCancel={() => {
            setData(undefined);
          }}
          data={data}
          sendEmail={(
            email: string,
            subject: string,
            body: string,
            attachments: File[],
          ) =>
            sendEmail(email, subject, body, attachments).then(() => {
              setData(undefined);
            })
          }
          draftEmail={draftEmail}
          updateDraftEmail={updateDraftEmail}
          sendDraftEmail={sendDraftEmail}
        />
      )}

      <div ref={targetRef} />
    </EmailContentContainer>
  );
};
