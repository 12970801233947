import React, { useCallback, useEffect, useState } from 'react';
import {
  DeleteIcon,
  DownloadIcon,
  LeftArrowIcon,
  SortIcon,
} from '../../../icons';
import {
  ATag,
  Badge,
  Button,
  CardContainer,
  DragDropUpload,
  Table,
  TitlePage,
  ValidatePlacementForm,
} from '../../../components';
import { PlacementDetailsContainer } from './placement-details.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  calculateWeeksDifference,
  formatDate,
  uploadPlacementFiles,
} from '../../../utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Application,
  Company,
  EApplicationApprovalStatus,
  ETimesheetApproval,
  HostedFile,
  Invoice,
  Timesheet,
} from '../../../backend/careo-api';
import { SideModal } from '../../../components/modals';
import {
  downloadFileByInvoice,
  downloadFileByTimesheet,
  formatCurrency,
} from '../../../utils';
import { ConfirmModal } from '../../../components/modals/confirm-modal/confirm-modal.component';
import { getStatus } from '../../../utils/status.utils';
import { DocumentsListComponent } from '../../../components/document';

enum EPlacementDetailsTabs {
  GENERAL = 'General',
  DOCUMENTS = 'Documents',
  TIMESHEETS = 'Timesheets',
  INVOICES = 'Invoices',
}

const tabs = [
  EPlacementDetailsTabs.GENERAL,
  EPlacementDetailsTabs.TIMESHEETS,
  EPlacementDetailsTabs.INVOICES,
  EPlacementDetailsTabs.DOCUMENTS,
];

export const PlacementDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isModalOpen, setIsModalOpen] = useState<Application | null>(null);
  const [selectedTab, setSelectedTab] = useState(EPlacementDetailsTabs.GENERAL);
  const [placement, setPlacement] = useState<Application | null>(null);
  const [timesheetsList, setTimesheetsList] = useState<Timesheet[]>([]);
  const [invoicesList, setInvoices] = useState<Invoice[]>([]);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [recordToDelete, setRecordToDelete] = useState<Timesheet>();
  const [documents, setDocuments] = useState<HostedFile[]>([]);

  const onClickDownloadTimeSheet = (timesheet: Timesheet) => {
    if (timesheet.approval === 'Approved') {
    } else {
      toast.warning('Timesheet should be approved to be downloaded');
    }
  };

  const deleteTimesheet = useCallback(() => {
    AxiosInstance.timesheets
      .timesheetsControllerDelete(recordToDelete?._id!)
      .then(() => {
        toast.success('Timesheet Deleted Successfully.');
        getTimesheets();
        setRecordToDelete(undefined);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  }, [recordToDelete]);

  const onClickGoBack = () => {
    navigate(-1);
  };

  const getPlacementDetails = () => {
    AxiosInstance.applications
      .applicationsControllerGetApplication(id!)
      .then((response) => {
        setPlacement(response as any);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getTimesheets = async () => {
    AxiosInstance.timesheets
      .timesheetsControllerFindAll({
        placementId: id!,
      })
      .then((response) => {
        const result = response.data as unknown as Timesheet[];
        setTimesheetsList(result);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getInvoices = async () => {
    AxiosInstance.invoices
      .invoicesControllerFindAll({
        placementId: id!,
      })
      .then((response) => {
        const result = response.data as unknown as Invoice[];
        setInvoices(result);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getCompany = async () => {
    AxiosInstance.company
      .companyControllerFindOne()
      .then((response) => {
        setCompany(response as any);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getPlacementDocuments = () => {
    AxiosInstance.files
      .filesControllerFindPlacementDocs(id!)
      .then((result) => {
        setDocuments(result as unknown as HostedFile[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const handleFileChange = async (files: File[]) => {
    await uploadPlacementFiles(id!, files)
      .then(() => {
        toast.success('Documents uploaded successfully');
        getPlacementDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickDelete = (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getPlacementDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  useEffect(() => {
    getPlacementDetails();
    getTimesheets();
    getInvoices();
    getCompany();
    getPlacementDocuments();

    if (queryParams.get('tab'))
      setSelectedTab(
        (queryParams.get('tab') as EPlacementDetailsTabs) ||
          EPlacementDetailsTabs.GENERAL,
      );
  }, []);

  if (!placement) {
    return <></>;
  }

  return (
    <>
      <PlacementDetailsContainer>
        <div className="placement-header">
          <div className="back-button" onClick={onClickGoBack}>
            <LeftArrowIcon />
          </div>
          <TitlePage>Detail Placement</TitlePage>
        </div>

        <CardContainer className="placement-information-container">
          <div className="tab-container">
            <div className="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item ${el === selectedTab && 'active'}`}
                  onClick={() => setSelectedTab(el)}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div className="tab-content-container">
              {selectedTab === EPlacementDetailsTabs.GENERAL && (
                <>
                  <div className="info-card">
                    <div className="info-card-header">
                      <div className="info-card-title">Placed Candidate</div>
                      {placement.approvalStatus ===
                        EApplicationApprovalStatus.NOT_ACTIVE && (
                        <Button
                          type="primary"
                          onClick={() => setIsModalOpen(placement)}
                        >
                          Validate
                        </Button>
                      )}
                    </div>
                    <hr />
                    <div className="info-card-content row">
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Full Name</div>
                        <div className="info-item-data col-8">
                          {placement.candidate?.firstName}{' '}
                          {placement.candidate?.lastName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Gender</div>
                        <div className="info-item-data col-8">
                          {placement.candidate?.gender || '-'}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Date of Birth
                        </div>
                        <div className="info-item-data col-8">
                          {placement.candidate?.birthDate
                            ? formatDate(placement.candidate?.birthDate)
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Pay Amount</div>
                        <div className="info-item-data col-8">
                          {placement.job?.payAmount
                            ? `${formatCurrency(
                                placement.job?.payAmount,
                              )} per hour`
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Nationality</div>
                        <div className="info-item-data col-8">
                          {placement.candidate?.nationality || '-'}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4"> Charge</div>
                        <div className="info-item-data col-8">
                          {placement.fee
                            ? `${formatCurrency(placement.fee)} per hour`
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Email Address
                        </div>
                        <div className="info-item-data col-8">
                          {placement.candidate?.email}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Length of contract
                        </div>
                        <div className="info-item-data col-8">
                          {
                            calculateWeeksDifference(
                              new Date(placement.availableFrom),
                              new Date(placement.availableTo),
                            ).label
                          }
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Phone Number
                        </div>
                        <div className="info-item-data col-8">
                          {placement.candidate?.phoneNumber}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Hours per week{' '}
                        </div>
                        <div className="info-item-data col-8">
                          {placement.job?.hoursPerWeek}{' '}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Start Date</div>
                        <div className="info-item-data col-8">
                          {formatDate(placement.availableFrom)}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">End Date</div>
                        <div className="info-item-data col-8">
                          {formatDate(placement.availableTo)}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4"> VAT</div>
                        <div className="info-item-data col-8">
                          {placement.vat ? placement.vat + '%' : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Margin per hour
                        </div>
                        <div className="info-item-data col-8">
                          {placement.fee
                            ? `${formatCurrency(
                                placement.fee - placement.job?.payAmount,
                              )}`
                            : '-'}
                        </div>
                      </div>

                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Expected contract Margin
                        </div>
                        <div className="info-item-data col-8">
                          {placement.fee
                            ? formatCurrency(
                                Number(
                                  placement.fee - placement.job?.payAmount,
                                ) *
                                  Number(
                                    calculateWeeksDifference(
                                      new Date(placement.availableFrom),
                                      new Date(placement.availableTo),
                                    ).value,
                                  ) *
                                  Number(placement.job?.hoursPerWeek),
                              )
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          On Call Rate
                        </div>
                        <div className="info-item-data col-8">
                          {placement.onCallRate
                            ? `${formatCurrency(
                                placement.onCallRate,
                              )}  per hour`
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          On call charge
                        </div>
                        <div className="info-item-data col-8">
                          {placement.onCallFee
                            ? `${formatCurrency(placement.onCallFee)}  per hour`
                            : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {selectedTab === EPlacementDetailsTabs.DOCUMENTS && (
                <>
                  <div className="info-card">
                    <div className="info-card-title">Personal Documents</div>
                    <hr />
                    <DocumentsListComponent
                      documents={documents}
                      onClickDelete={onClickDelete}
                      getDocuments={getPlacementDocuments}
                    />
                  </div>
                  <div className="info-card">
                    <DragDropUpload onUpload={handleFileChange} />
                  </div>
                </>
              )}
              {selectedTab === EPlacementDetailsTabs.TIMESHEETS && (
                <div className="data-table-container">
                  <Table>
                    <thead>
                      <tr>
                        <th className="checkbox-table">
                          <input type="checkbox" />
                        </th>
                        <th>
                          <div>
                            <label>Employee Name</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Job Title</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>From</label>
                            <SortIcon />
                          </div>
                        </th>

                        <th>
                          <div>
                            <label>To</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Client Name</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Department</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Team</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Status</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Approval</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {timesheetsList.map((timesheet) => {
                        const isApproved =
                          timesheet?.approval === ETimesheetApproval.Approved;
                        return (
                          <tr key={timesheet._id}>
                            <td className="checkbox-table">
                              <input type="checkbox" />
                            </td>
                            <td>
                              <div className="name-item">
                                <div>
                                  <div>
                                    {timesheet?.placement?.candidate?.firstName}{' '}
                                    {timesheet?.placement?.candidate?.lastName}
                                  </div>
                                  <div className="email">
                                    {timesheet?.placement?.candidate?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="name-item">
                                <div>
                                  <div> {timesheet?.placement?.job?.grade}</div>
                                  <div className="email">
                                    {timesheet?.placement?.job?.specialty}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {formatDate(timesheet?.timings?.[0].date ?? '')}
                            </td>
                            <td>
                              {formatDate(
                                timesheet?.timings?.[
                                  timesheet.timings.length - 1
                                ]?.date ?? '',
                              )}
                            </td>
                            <td>
                              {timesheet?.placement?.job?.client?.clientName}
                            </td>
                            <td>
                              <div className="name-item">
                                <div>
                                  <div>
                                    {
                                      timesheet?.placement?.job?.client
                                        ?.firstName
                                    }{' '}
                                    {
                                      timesheet?.placement?.job?.client
                                        ?.lastName
                                    }
                                  </div>
                                  <div className="email">
                                    {timesheet?.placement?.job?.client?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>{timesheet?.placement?.job?.grade}</td>
                            <td>{timesheet?.placement?.job?.specialty}</td>
                            <td>
                              <Badge
                                text={timesheet?.approval}
                                type={getStatus(
                                  timesheet?.approval === 'Approved'
                                    ? 'Active'
                                    : timesheet?.approval === 'Rejected'
                                    ? 'On leave'
                                    : 'On boarding',
                                )}
                              />
                            </td>
                            <td>
                              <div className="action-item">
                                <div className="action-item">
                                  <div
                                    className={`download-icon ${
                                      isApproved ? '' : 'disabled'
                                    }`}
                                    onClick={() =>
                                      isApproved &&
                                      downloadFileByTimesheet(timesheet)
                                    }
                                  >
                                    <DownloadIcon />
                                  </div>
                                </div>
                                <div className="action-item">
                                  <div
                                    className={`delete-icon ${
                                      !isApproved ? '' : 'disabled'
                                    }`}
                                    onClick={() =>
                                      !isApproved &&
                                      setRecordToDelete(timesheet)
                                    }
                                  >
                                    <DeleteIcon />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
              {selectedTab === EPlacementDetailsTabs.INVOICES && (
                <div className="data-table-container">
                  <Table>
                    <thead>
                      <tr>
                        <th className="checkbox-table">
                          <input type="checkbox" />
                        </th>
                        <th>
                          <div>
                            <label>Invoice</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Type</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Generated</label>
                            <SortIcon />
                          </div>
                        </th>

                        <th>
                          <div>
                            <label>Due</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Status</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Approval</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th className="action-th">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoicesList.map((invoice) => {
                        const reminderDate = new Date(
                          invoice?.timesheet?.generatedDate,
                        );
                        reminderDate.setDate(reminderDate.getDate() + 30);

                        return (
                          <tr key={invoice.referenceNumber}>
                            <td className="checkbox-table">
                              <input type="checkbox" />
                            </td>
                            <td>Invoice-{invoice.referenceNumber}.pdf</td>
                            <td>Client</td>
                            <td>
                              {formatDate(invoice?.timesheet?.generatedDate)}
                            </td>
                            <td>{formatDate(reminderDate.toJSON())}</td>
                            <td>
                              <Badge text={'Unpaid'} type={'warning'} />
                            </td>
                            <td>{invoice?.timesheet?.approval}</td>
                            <td>
                              <div className="action-item">
                                <div
                                  className="download-icon"
                                  onClick={() => downloadFileByInvoice(invoice)}
                                >
                                  <DownloadIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </CardContainer>
      </PlacementDetailsContainer>
      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={setIsModalOpen as any}
        title="Validate Placement"
      >
        <ValidatePlacementForm
          application={isModalOpen as any}
          getPlacementDetails={getPlacementDetails}
          setIsOpen={setIsModalOpen as any}
        />
      </SideModal>

      <ConfirmModal
        isOpen={recordToDelete !== undefined}
        title="Delete Timesheet"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteTimesheet()}
      >
        Do you want to delete <b>this timesheet</b>.
      </ConfirmModal>
    </>
  );
};
