import React, { useEffect, useState } from 'react';
import { Select, TitleCard, FieldText, Button, FieldFile } from '../../ui';
import {
  countriesWithRegions,
  regionsWithCounties,
  titles,
} from '../../../constants';
import { useForm } from 'react-hook-form';
import { CreateClientDto, User } from '../../../backend/careo-api';
import { NewClientFormContainer } from './new-client-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { clientSchema } from '../../../utils/validators/clients.validator';
import { handleFormsServerErrors } from '../../../utils';

type NewClientFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const NewClientForm = ({ onCancel, onSuccess }: NewClientFormProps) => {
  const [usersList, setUsersList] = useState<User[]>([]);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    setFocus,
  } = useForm<CreateClientDto>({
    resolver: yupResolver(clientSchema as any),
  });

  const formValues = watch();

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.clients
      .clientsControllerCreateClient(values)
      .then(() => {
        toast.success('Client added successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);

        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerGetAllUsers()
      .then((response) => {
        setUsersList(response as unknown as User[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <NewClientFormContainer>
      <Select
        placeholder="Select recruiter"
        label="Recruiter"
        required
        options={[
          ...usersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          })),
        ]}
        register={register('userId')}
        error={errors.userId}
      />
      <TitleCard>Personal Details</TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Client Name"
        required
        register={register('clientName')}
        error={errors.clientName}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Client Site Name"
        register={register('clientSiteName')}
        error={errors.clientSiteName}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Client Site"
        register={register('clientSite')}
        error={errors.clientSite}
      />
      {/* address form */}
      <>
        <TitleCard>Address</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          label="Street Address"
          register={register('address.street')}
          error={errors?.address?.street}
        />
        <FieldText
          placeholder="Enter here ..."
          label="City"
          register={register('address.city')}
          error={errors?.address?.city}
        />
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select country"
              label="Country"
              options={countriesWithRegions.map((el) => ({
                label: el.country,
                value: el.country,
              }))}
              register={register('address.country')}
              error={errors?.address?.country}
            />
          </div>
          <div className="col-md-6">
            <Select
              placeholder="Select Region"
              label="Region"
              options={
                countriesWithRegions
                  .find((el) => el.country === formValues.address?.country)
                  ?.regions?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.region')}
              error={errors?.address?.region}
              disabled={!formValues.address?.country}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select County"
              label="County"
              options={
                regionsWithCounties
                  .find((el) => el.region === formValues.address?.region)
                  ?.counties?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.county')}
              error={errors?.address?.county}
              disabled={!formValues.address?.region}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Postcode"
              register={register('address.zip')}
              error={errors?.address?.zip}
            />
          </div>
        </div>
      </>
      <>
        <TitleCard>Primary Contact</TitleCard>
        <div className="row">
          <div className="col-md-2">
            <Select
              placeholder="Title"
              label="Title"
              required
              options={titles.map((el) => ({ label: el, value: el }))}
              register={register('title')}
              error={errors.title}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="First Name"
              required
              register={register('firstName')}
              error={errors.firstName}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Last Name"
              required
              register={register('lastName')}
              error={errors.lastName}
            />
          </div>
        </div>
        <FieldText
          placeholder="Enter here ..."
          label="Job title"
          register={register('jobTitle')}
          error={errors.jobTitle}
        />
        <FieldText
          placeholder="Enter here ..."
          label="Email"
          required
          register={register('email')}
          error={errors.email}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Mobile Phone Number"
              required
              register={register('phoneNumber')}
              error={errors.phoneNumber}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Work Phone Number"
              register={register('workPhoneNumber')}
              error={errors.workPhoneNumber}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Website"
              register={register('website')}
              error={errors.website}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Fax number"
              register={register('faxNumber')}
              error={errors.faxNumber}
            />
          </div>
        </div>
        <TitleCard>Bank details</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          label="Bank Name"
          register={register('bank.name')}
          error={errors.bank?.name}
        />
        <FieldText
          placeholder="Enter here ..."
          label="Bank Account Name"
          register={register('bank.accountName')}
          error={errors.bank?.accountName}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Sort Code"
              register={register('bank.sortNumber')}
              error={errors.bank?.sortNumber}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Account Number"
              register={register('bank.accountNumber')}
              error={errors.bank?.accountNumber}
            />
          </div>
        </div>
      </>
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewClientFormContainer>
  );
};
