import React, { Dispatch, SetStateAction } from 'react';
import { Select, TitleCard, FieldText, Button, RadioGroup } from '../../ui';
import { useForm } from 'react-hook-form';
import {
  CampaignTemplate,
  CreateCampaignDto,
  EChannel,
  EFrequency,
} from '../../../backend/careo-api';
import { NewCampaignFormContainer } from './new-campaign-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { handleFormsServerErrors } from '../../../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { campaignSchema } from '../../../utils/validators/campaign.validator';

type NewCampaignForm = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getCampaigns: () => void;
  templateLists: CampaignTemplate[];
};

export const NewCampaignForm = ({
  setIsOpen,
  getCampaigns,
  templateLists,
}: NewCampaignForm) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<CreateCampaignDto>({
    resolver: yupResolver(campaignSchema as any),
  });
  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.campaigns
      .campaignsControllerCreate({
        ...values,
        frequency: values.frequency,
        runningTime: values.runningTime,
      })
      .then(() => {
        toast.success('Campaign added successfully');
        getCampaigns();
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  return (
    <NewCampaignFormContainer>
      <div className="row">
        <div className="col-md-7">
          <FieldText
            placeholder="Enter here ..."
            label="Campaign name"
            required
            register={register('name', { required: true })}
            error={errors?.name}
          />
        </div>
        <div className="col-md-5">
          <Select
            label="Channel"
            required
            register={register('channel', { required: true })}
            options={[
              {
                value: EChannel.Whatsapp,
                label: 'WhatsApp',
              },
              {
                value: EChannel.Email,
                label: 'Email',
              },
            ]}
            error={errors?.channel}
          />
        </div>
      </div>
      <Select
        label="Template"
        options={templateLists.map((el) => ({
          label: el.name,
          value: el._id,
        }))}
        required
        register={register('campaignTemplateId', { required: true })}
        error={errors?.campaignTemplateId}
      />
      <TitleCard>Schedule type</TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Daily',
            value: EFrequency.Daily,
          },
          {
            label: 'Weekly',
            value: EFrequency.Weekly,
          },
          {
            label: 'Monthly',
            value: EFrequency.Monthly,
          },
        ]}
        name={'frequency'}
        register={register('frequency', { required: true })}
        error={errors?.frequency}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Start Date"
        required
        type="date"
        register={register('startDate', { required: true })}
        error={errors?.startDate}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Send Time (GMT)"
        required
        type="time"
        register={register('runningTime', { required: true })}
        error={errors?.runningTime}
      />
      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewCampaignFormContainer>
  );
};
