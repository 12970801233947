import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import {
  CardContainer,
  MessageList,
  Select,
  SearchInput,
  Button,
} from '../../components';
import { EmailsPageContainer } from './emails.style';
import {
  Candidate,
  ChatListItemDto,
  Client,
  EmailsCandidatesCRM,
  EmailsClientsCRM,
  HostedFile,
  EWebsocketType,
  MEMBER_TYPE,
  UserEmailsResponseDto,
  BOX_TYPE,
  EmailBody,
  EmailAttachment,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  draftEmailRequest,
  sendEmailRequest,
  socket,
  updateDraftEmailRequest,
} from '../../utils';
import { PagesUrls } from '../../routes/page-urls';
import { isCRMApp } from '../../environment/app.type';
import { toast } from 'react-toastify';
import { EmailContent } from '../../components/emails/emails-content.component';
import { EmailsTypeList } from '../../components/emails/emails-type-list.component';
import { EmailsTitleList } from '../../components/emails/emails-title-lists.component';
import { PlusIcon } from '../../icons';
import EmailCreate from '../../components/emails/email-create.component';
import { useAuth } from '../../contexts/auth.context';

type EmailsPageProps = {};

export enum EmailSender {
  Candidate = 'candidate',
  Client = 'client',
  User = 'user',
}

export interface EmailBodyUi extends EmailBody {
  isDraft: boolean;
  isSent: boolean;
}

export interface EmailsEmailUi extends UserEmailsResponseDto {
  emails: EmailBodyUi[];
  isLoading: boolean;
}

export interface EmailsMessageUi
  extends Omit<EmailsCandidatesCRM, 'candidate'> {
  receiverId: string;
  member: Candidate | Client;
}

export const EmailsPage = ({}: EmailsPageProps) => {
  const { user } = useAuth();

  const [emailType, setEmailType] = useState<BOX_TYPE>(BOX_TYPE.INBOX);
  const [selectedEmail, setSelectedEmail] = useState<EmailBodyUi>();

  const [isCreateEmailOpen, setIsCreateEmailOpen] = useState(false);

  const [dataEmails, setDataEmails] = useState<EmailsEmailUi>({
    isLoading: true,
    emails: [],
    nextPageToken: '',
    previousPageToken: '',
  });

  const clickLinkGmail = () => {
    AxiosInstance.emails
      .emailsControllerGetAuthUrl({ portal: process.env.REACT_APP_NAME! })
      .then((response: any) => {
        window.location.href = response.url;
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  const getEmails = async (pageToken: string = '') => {
    setDataEmails({
      isLoading: true,
      emails: [],
      nextPageToken: '',
      previousPageToken: '',
    });
    await AxiosInstance.emails
      .emailsControllerGetUserEmails({
        pageToken: pageToken,
        box: emailType,
        pageSize: 10,
      })
      .then((response) => {
        const result = response as unknown as UserEmailsResponseDto;
        setDataEmails({
          isLoading: false,
          emails: result.emails.map((el) => {
            const isDraft = el.labels.includes(BOX_TYPE.DRAFT);
            const isSent = isDraft || el.labels.includes(BOX_TYPE.SENT);

            return {
              ...el,
              sender: isSent ? 'me' : el.from,
              receiver: isSent ? el.from : 'me',
              isDraft: isDraft,
              isSent: isSent,
            };
          }),
          previousPageToken: result.previousPageToken,
          nextPageToken: result.nextPageToken,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  //  #################### EMAILS ####################
  const sendEmail = async (
    email: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => {
    if (!email || !subject || !body) {
      toast.warning('Email, subject and body are required !');
      return;
    }

    const properEmail = email?.match(/<([^>]+)>/)?.[1] || email;

    await sendEmailRequest(properEmail, subject, body, attachments)
      .then((response) => {
        toast.success('Email sent successfully');
        getEmails();
        setIsCreateEmailOpen(false);
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const draftEmail = async (
    email: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => {
    if (!email || !subject || !body) {
      toast.warning('Email, subject and body are required !');
      return;
    }

    await draftEmailRequest(email, subject, body, attachments)
      .then((response) => {
        toast.success('Email saved successfully');
        getEmails();
        setIsCreateEmailOpen(false);
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const updateDraftEmail = async (
    id: string,
    recipient: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: EmailAttachment[],
  ) => {
    if (!subject || !body) {
      toast.warning('Subject and body are required !');
      return;
    }

    await updateDraftEmailRequest(
      id,
      recipient,
      subject,
      body,
      attachments,
      hostedFiles,
    )
      .then((response) => {
        toast.success('Email saved successfully');
        getEmails();
        setIsCreateEmailOpen(false);
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const sendDraftEmail = async (
    id: string,
    recipient: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: EmailAttachment[],
  ) => {
    if (!subject || !body) {
      toast.warning('Subject and body are required !');
      return;
    }

    await updateDraftEmailRequest(
      id,
      recipient,
      subject,
      body,
      attachments,
      hostedFiles,
    )
      .then((response) => {
        AxiosInstance.emailsDrafts
          .emailDraftsControllerSendDraftEmail(id)
          .then(() => {
            toast.success('Email sent successfully');
            getEmails();
          });
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  const deleteEmail = async (id: string, isDraft?: boolean) => {
    const deleteEmailRequest = isDraft
      ? AxiosInstance.emailsDrafts.emailDraftsControllerDeleteDraftEmail
      : AxiosInstance.emails.emailsControllerDeleteEmail;

    await deleteEmailRequest(id)
      .then(() => {
        toast.success('Email deleted successfully');
        getEmails();
        setIsCreateEmailOpen(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  useEffect(() => {
    if (user?.emailLinked) {
      getEmails();
    }
  }, [emailType]);

  if (!user?.emailLinked) {
    return (
      <EmailsPageContainer>
        <CardContainer className="email-not-linked">
          <div> Gmail is not linked</div>
          <div className="gmail-link" onClick={clickLinkGmail}>
            Click here to link your Gmail
          </div>
        </CardContainer>
      </EmailsPageContainer>
    );
  }

  return (
    <EmailsPageContainer>
      <div className="emails-header">
        <div className="left-filter"></div>
        <div className="right-filter">
          {/* <SearchInput
            placeholder={'Search'}
            onChange={(e) => setSearch(e.target.value ?? '')}
          /> */}
          <Button type="primary" onClick={() => setIsCreateEmailOpen(true)}>
            <PlusIcon /> New email
          </Button>
        </div>
      </div>
      <CardContainer className="emails-container">
        <div className="left-container">
          <EmailsTypeList
            emailType={emailType}
            setEmailType={setEmailType}
            setSelectedEmail={setSelectedEmail}
          />
        </div>
        <div className="right-container">
          {isCreateEmailOpen ? (
            <EmailCreate
              onCancel={() => {
                setIsCreateEmailOpen(false);
              }}
              data={undefined}
              sendEmail={sendEmail}
              draftEmail={draftEmail}
              updateDraftEmail={updateDraftEmail}
              sendDraftEmail={sendDraftEmail}
            />
          ) : (
            <EmailsTitleList
              dataEmails={dataEmails}
              getEmails={getEmails}
              selectedEmail={selectedEmail}
              setSelectedEmail={setSelectedEmail}
              deleteEmail={deleteEmail}
              sendEmail={sendEmail}
              draftEmail={draftEmail}
              sendDraftEmail={sendDraftEmail}
              updateDraftEmail={updateDraftEmail}
            />
          )}
        </div>
      </CardContainer>
    </EmailsPageContainer>
  );
};
