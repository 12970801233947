import * as yup from 'yup';
import { regex } from '../regex.utils';

export const AddUserSchema = yup.object().shape({
  role: yup.string().required('Role is required'),
  firstName: yup
    .string()
    .required('First name is required')
    .matches(
      regex.name,
      'First Name must contain only letters, spaces, apostrophes, and hyphens',
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(
      regex.name,
      'Last Name must contain only letters, spaces, apostrophes, and hyphens',
    ),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email address is required'),

  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(20, 'Password must be at most 20 characters')
    .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
    .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
    .matches(/\d/, 'Password must have a number')
    .matches(
      /[!+@#$%^&*()\-_"=+{}; :,<.>]/,
      'Password must have a special character',
    ),

  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(20, 'Password must be at most 20 characters')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),

  phoneNumber: yup
    .string()
    .matches(
      /^\+\d+$/,
      'Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars')
    .required('Phone number is required.'),
});

export const UpdateUserSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .matches(
      regex.name,
      'First Name must contain only letters, spaces, apostrophes, and hyphens',
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(
      regex.name,
      'Last Name must contain only letters, spaces, apostrophes, and hyphens',
    ),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email address is required'),
  phoneNumber: yup
    .string()
    .matches(
      /^\+\d+$/,
      'Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars')
    .required('Phone number is required.'),
});
