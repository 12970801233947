import React, { useEffect, useRef, useState } from 'react';
import {
  AttachFileIcon,
  CancelIcon,
  EditIcon,
  RightArrowIcon,
} from '../../icons';
import { Button, FieldText, TextEditor } from '../ui';
import { EmailContentContainer } from './emails-content.style';
import { EmailAttachment } from '../../backend/careo-api';
import { EmailBodyUi } from '../../pages/emails';

type EmailCreateProps = {
  onCancel: () => void;
  data?: EmailBodyUi;
  sendEmail: (
    email: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => Promise<void>;
  draftEmail: (
    email: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => Promise<void>;
  updateDraftEmail: (
    messageId: string,
    recipient: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: EmailAttachment[],
  ) => Promise<void>;
  sendDraftEmail: (
    messageId: string,
    recipient: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: EmailAttachment[],
  ) => Promise<void>;
};

const EmailCreate = ({
  onCancel,
  data,
  sendEmail,
  draftEmail,
  updateDraftEmail,
  sendDraftEmail,
}: EmailCreateProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [hostedFiles, setHostedFiles] = useState<EmailAttachment[]>([]);
  const [updatedId, setUpdatedId] = useState('');

  const clearData = () => {
    setSubject('');
    setBody('');
    setAttachments([]);
    setHostedFiles([]);
    setUpdatedId('');
    onCancel();
  };

  const onClickSendEmail = () => {
    data?.isDraft
      ? sendDraftEmail(
          updatedId,
          recipient,
          subject,
          body,
          attachments,
          hostedFiles,
        )
      : sendEmail(recipient, subject, body, attachments);
  };

  useEffect(() => {
    if (data) {
      if (data?.isDraft) {
        setUpdatedId(data.messageId ?? '');
        // setHostedFiles((data?.attachments as EmailAttachment[]) ?? []);
      }
      setRecipient(data?.to?.[0] ?? '');
      setSubject(data?.subject ?? '');
      setBody(data?.bodyHtml ?? '');
    }
  }, []);

  return (
    <EmailContentContainer>
      <div className="create-email-container">
        <div className="to-input">
          To:{' '}
          {data?.to.length ? (
            <FieldText value={`${recipient}`} disabled />
          ) : (
            <FieldText
              value={recipient}
              onChange={(e) => {
                setRecipient(e.target.value);
              }}
            />
          )}
        </div>
        <div className="subject-input">
          Subject:{' '}
          <FieldText
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="email-attachments">
          <button
            style={{ background: 'none', border: 'none' }}
            onClick={() => fileInputRef.current?.click()}
          >
            <AttachFileIcon />
          </button>
          <div className="attachments-list">
            {!(attachments.length + hostedFiles.length) ? (
              'No attachments'
            ) : (
              <>
                {hostedFiles.map((el, index) => (
                  <div className="attachment-file">
                    <div>
                      {(el as EmailAttachment).filename ?? 'unknown file'}
                    </div>
                    <CancelIcon
                      onClick={() =>
                        setHostedFiles((prev: any) =>
                          prev.filter((_: any, i: number) => i !== index),
                        )
                      }
                    />
                  </div>
                ))}
                {attachments.map((el, index) => (
                  <div className="attachment-file">
                    <div>{el.name}</div>
                    <CancelIcon
                      onClick={() =>
                        setAttachments((prev) =>
                          prev.filter((_, i) => i !== index),
                        )
                      }
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(event) => {
              if (event.target.files) {
                const files = Array.from(event.target.files);
                setAttachments((prev) => [...prev, ...files]);
              }
            }}
            multiple
          />
        </div>
        <TextEditor value={body} onChange={(value) => setBody(value)} />
      </div>
      <div className="actions-buttons">
        <Button type="danger" onClick={clearData} className="cancel-button">
          <CancelIcon /> Cancel
        </Button>
        <Button
          type="default"
          onClick={() => {
            updatedId
              ? updateDraftEmail(
                  updatedId,
                  recipient,
                  subject,
                  body,
                  attachments,
                  hostedFiles,
                )
              : draftEmail(recipient, subject, body, attachments);
          }}
        >
          <EditIcon /> {updatedId ? 'Update Draft' : 'Draft'}
        </Button>
        <Button type="primary" onClick={onClickSendEmail}>
          Send <RightArrowIcon />
        </Button>
      </div>
    </EmailContentContainer>
  );
};

export default EmailCreate;
