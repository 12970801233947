import React from 'react';
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartContainer = styled.div<{ size?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .chart-details {
    box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
    width: ${({ size = 200 }) => size * 0.5}px;
    height: ${({ size = 200 }) => size * 0.5}px;
    border-radius: ${({ size = 200 }) => size * 0.25}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    .chart-title {
      font-size: 20px;
    }
    .chart-subtitle {
      font-size: 15px;
      color: #808080;
    }
  }

  .chart-container {
    padding: 10px;
    width: ${({ size = 200 }) => size}px;
  }
`;

export type DonutChartConfig = {
  labels: string[];
  backgroundColors?: string[];
  borderColors?: string[];
  title?: string;
  subtitle?: string;
  size?: number;
};

type DonutChartProps = {
  data: number[];
  config: DonutChartConfig;
};

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  config: {
    labels,
    backgroundColors = ['#ee2f2f', '#ffd023', '#27a376'],
    borderColors = ['#ee2f2f', '#ffd023', '#27a376'],
    title = 'Chart',
    subtitle = 'Details',
    size = 200,
  },
}) => {
  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
        borderRadius: 4,
        spacing: 4,
        hoverOffset: 20, // How much the arc expands on hover
      },
    ],
  };

  const options = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const totalTasks = data.reduce((acc, val) => acc + val, 0);

  return (
    <ChartContainer size={size}>
      <div className="chart-container">
        <Doughnut data={chartData} options={options} />
      </div>
      <div className="chart-details">
        <div className="chart-title">{totalTasks}</div>
        <div className="chart-subtitle">{subtitle}</div>
      </div>
    </ChartContainer>
  );
};

export default DonutChart;
